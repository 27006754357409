import { Suspense, useCallback, useMemo, useRef, useState } from 'react'
import { Platform } from 'react-native'
import type { Route } from '@react-navigation/native'
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
} from '@react-navigation/native'
import { QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import * as SplashScreen from 'expo-splash-screen'
import { StatusBar } from 'expo-status-bar'
import i18next from 'i18next'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { ThemeProvider } from 'styled-components/native'

import Navigation from '@/navigation'
import linkingConfiguration from '@/navigation/linkingConfiguration'
import { navigationRef } from '@/navigation/utils'

import ErrorBoundary from '@/components/ErrorBoundary'
import { FullScreenLoading } from '@/components/Loading'

import * as analytics from '@/utils/analytics'
import { Provider as PostHogProvider } from '@/utils/analytics/posthog'
import awsConfig from '@/utils/auth'
import NotificationsProvider from '@/utils/context/NotificationsProvider'
import useCachedResources from '@/utils/hooks/useCachedResources'
import useSpeziaFont from '@/utils/hooks/useSpeziaFont'
import useTheme from '@/utils/hooks/useTheme'
import queryClient from '@/utils/queryClient'
import Sentry, { routingInstrumentation, wrap } from '@/utils/sentry'

import GlobalStyles from '@/globalStyled'

import '@/localization'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

Amplify.configure(awsConfig)
analytics.initialize()

const isWeb = Platform.OS === 'web'
if (!isWeb) {
  SplashScreen.preventAutoHideAsync()
}

serviceWorkerRegistration.register()

const loadingFallback = <FullScreenLoading />

i18next.on('languageChanged', (language) => {
  Sentry.setTag('i18next-language', language)
})

function Root() {
  const [isFontLoaded] = useSpeziaFont()
  const { theme, colorScheme } = useTheme()
  const isCachedResourcesLoaded = useCachedResources()
  const routeNameRef = useRef<string | null>(null)

  const [isTranslationsLoaded] = useState(i18next.isInitialized)

  const onLayoutRootView = useCallback(() => {
    if (!isFontLoaded || !isWeb) return

    void SplashScreen.hideAsync()
  }, [isFontLoaded])

  const handleStateChange = useCallback(() => {
    // Based on https://reactnavigation.org/docs/screen-tracking/#example
    const previousRouteName = routeNameRef.current
    const currentRouteName = navigationRef.getCurrentRoute()?.name ?? null

    if (previousRouteName !== currentRouteName) {
      routeNameRef.current = currentRouteName

      if (currentRouteName) analytics?.screenView(currentRouteName)
    }
  }, [])

  const getDocumentTitle = useMemo(
    () => ({
      formatter: (options?: Record<string, any>, route?: Route<string>) =>
        `${options?.title ?? route?.name ?? 'Loading'} - OCHO Insured`,
    }),
    [],
  )

  const handleOnReady = useCallback(() => {
    routeNameRef.current = navigationRef.getCurrentRoute()?.name ?? null

    if (isWeb) return
    // Only Native
    routingInstrumentation?.registerNavigationContainer(navigationRef)
  }, [])

  const content = useMemo(() => {
    const isAssetsReady =
      isCachedResourcesLoaded &&
      isTranslationsLoaded &&
      (!isWeb || isFontLoaded)

    if (!isAssetsReady) {
      return loadingFallback
    }

    return <Navigation />
  }, [isFontLoaded, isTranslationsLoaded, isCachedResourcesLoaded])

  return (
    <>
      <StatusBar style="light" />
      <GlobalStyles />
      <SafeAreaProvider onLayout={onLayoutRootView}>
        <NavigationContainer
          ref={navigationRef}
          documentTitle={getDocumentTitle}
          fallback={loadingFallback}
          linking={linkingConfiguration}
          onReady={handleOnReady}
          onStateChange={handleStateChange}
          theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
        >
          <PostHogProvider>
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                  <NotificationsProvider>
                    <Suspense fallback={loadingFallback}>{content}</Suspense>
                  </NotificationsProvider>
                </QueryClientProvider>
              </ErrorBoundary>
            </ThemeProvider>
          </PostHogProvider>
        </NavigationContainer>
      </SafeAreaProvider>
    </>
  )
}

const RootWithSentry = wrap(Root)

export default RootWithSentry
