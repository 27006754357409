import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import { axiosInstance, getIdemPotencyKeyHeader } from '@/utils/api'
import type { UpdateApplicationField } from '@/utils/api/application'
import { applicationKeys, updateApplication } from '@/utils/api/application'
import { customerKeys } from '@/utils/api/customer'
import type { APIResponse, CustomerTask } from '@/utils/api/types'
import { getResponseData } from '@/utils/api/utils'
import { ApplicationStatus, InclusionStatus } from '@/utils/constants'
import {
  MissingApplicationId,
  MissingCustomerId,
  MissingOwnerId,
} from '@/utils/errors'
import Sentry from '@/utils/sentry'
import { useCustomerId } from '@/utils/zustand'

export enum TaskStatus {
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  AgentLocked = 'AGENT_LOCKED',
  Complete = 'COMPLETE',
  Cancelled = 'CANCELLED',
}

export const FINAL_TASK_STATUSES = [
  TaskStatus.Submitted,
  TaskStatus.Complete,
  TaskStatus.Cancelled,
]

export type GetPresignedUrlParams = {
  customerId: string | null
  applicationId?: string
  taskId?: string
  source: 'files' | 'camera' | 'photo-library'
}

export function createPresignedUrl({
  taskId,
  customerId,
  applicationId,
}: GetPresignedUrlParams) {
  const headers = getIdemPotencyKeyHeader()

  return axiosInstance
    .post<
      APIResponse<{ preSignedPostUrl: string }>
    >(applicationId ? `/autoFlow/applications/${applicationId}/documents/uploadUrl` : `/customers/${customerId}/tasks/${taskId}/uploadUrl`, undefined, { headers })
    .then(getResponseData)
}

export function useCreateUploadUrl(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof createPresignedUrl>>,
    AxiosError<unknown>,
    GetPresignedUrlParams
  >,
) {
  return useMutation({
    mutationFn: createPresignedUrl,
    mutationKey: ['create-upload-url'],
    ...options,
  })
}

export function addTaskAttachment({
  taskId,
  ownerId,
  attachments,
}: {
  taskId: string
  ownerId: string | null
  attachments: {
    location: string
    contentType: string
    type: string
    comments?: string
  }[]
}) {
  if (!ownerId) {
    throw new MissingOwnerId()
  }
  const headers = getIdemPotencyKeyHeader()

  return axiosInstance.patch<APIResponse<unknown>>(
    `/customers/${ownerId}/tasks/${taskId}/attachments`,
    { attachments },
    { headers },
  )
}

export function getCustomerTask(customerId: string | null, taskId: string) {
  if (!customerId) throw new MissingCustomerId()
  if (!taskId) throw new MissingCustomerId()

  return axiosInstance
    .get<APIResponse<CustomerTask>>(`/customers/${customerId}/tasks/${taskId}`)
    .then(getResponseData)
}

export function useTask(
  taskId: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerTask>>,
    AxiosError<unknown>
  >,
) {
  const customerId = useCustomerId()

  return useQuery({
    queryFn: () => getCustomerTask(customerId, taskId),
    queryKey: customerKeys.task(customerId, taskId),
    ...options,
  })
}

export function useTaskSignatureAnchors(
  taskId: string,
  options?: UseQueryOptions<
    unknown,
    AxiosError,
    { document: { signature: string; initial: string }; pages: any[] }
  >,
) {
  const customerId = useCustomerId()

  return useQuery({
    queryFn: () =>
      axiosInstance
        .get<
          APIResponse<unknown>
        >(`/customers/${customerId}/tasks/${taskId}/signatureAnchors`)
        .then(getResponseData),
    queryKey: ['documentTaskAnchors', customerId, taskId],
    ...options,
  })
}

export function submitTask({
  customerId,
  taskId,
  data = {},
}: {
  customerId: string | null
  taskId: string
  data?: object
}) {
  if (!customerId) throw new MissingCustomerId()
  if (!taskId) throw new Error('Missing taskId')

  return axiosInstance.patch<APIResponse<unknown>>(
    `/customers/${customerId}/tasks/${taskId}/submit`,
    // NOTE: we need to send an empty object to submit the task
    data,
    { headers: getIdemPotencyKeyHeader() },
  )
}

export function useSubmitTask(
  taskId: string,
  options?: UseMutationOptions<
    unknown,
    AxiosError,
    { signature: string; initial: string }
  >,
) {
  const customerId = useCustomerId()

  return useMutation({
    mutationFn: (data) => submitTask({ customerId, data, taskId }),
    mutationKey: ['submit-task'],
    ...options,
  })
}

export enum DocumentType {
  ProfilePhoto = 'PROFILE_PHOTO',
  IdMatricularId = 'ID_MATRICULAR_ID',
  IdDriverLicense = 'ID_DRIVER_LICENSE',
  IdPassport = 'ID_PASSPORT',
  IdStateId = 'ID_STATE_ID',
  IdOther = 'ID_OTHER',
  InsurancePolicyAuto = 'INSURANCE_POLICY_AUTO',
  InsurancePolicyAutoEvidence = 'INSURANCE_POLICY_AUTO_EVIDENCE',
  InsurancePolicyAutoEmail = 'INSURANCE_POLICY_AUTO_EMAIL',
  InsurancePolicyAutoEmailRaw = 'INSURANCE_POLICY_AUTO_EMAIL_RAW',
  InsurancePremiumFinanceLoanContract = 'INSURANCE_PREMIUM_FINANCE_LOAN_CONTRACT',
  InsurancePremiumFinanceNotice = 'INSURANCE_PREMIUM_FINANCE_NOTICE',
  PaymentOrderReceipt = 'PAYMENT_ORDER_RECEIPT',
  Signature = 'SIGNATURE',
  SignatureInitial = 'SIGNATURE_INITIAL',
  VehicleOdometer = 'VEHICLE_ODOMETER',
  VehicleVin = 'VEHICLE_VIN',
  VehicleCorners = 'VEHICLE_CORNERS',
  VehicleRegistration = 'VEHICLE_REGISTRATION',
  Other = 'OTHER',
  ProofAddress = 'PROOF_ADDRESS',
  ProofPriorInsurance = 'PROOF_PRIOR_INSURANCE',
}

export type DocumentAttachment = {
  location: string
  contentType: `${string}/${string}`
  type: DocumentType
  productIssuer: 'OCHO'
  comments?: string
  tags?: 'flow::proofDocumentation'[]
  /**
   * Typically will be the application ID
   * This will trigger an application update
   */
  path?: `${'drivers' | 'vehicles'}[${number}].${string}`
}

export type ApplicationDocumentAttachment = {
  keyId: string
  ownerId: string
  ownerType: string
  productId: any
  productType: string
  productIssuer: string
  contextId: string
  contextType: string
  location: string
  contentType: string
  type: string
  tags: string[]
  status: string
  statusReason: any
  comments: any
  preSignedGetUrl: string
  permissions: any
  id: string
  createdTimestamp: string
  updatedTimestamp: string
  createdBy: string
  modifiedBy: string
  version: number
}

export async function getApplicationDocumentAttachments({
  applicationId,
}: {
  applicationId: string
}) {
  if (!applicationId) throw new MissingApplicationId()

  const response = await axiosInstance
    .get<
      APIResponse<ApplicationDocumentAttachment[]>
    >(`/autoFlow/applications/${applicationId}/documents/attachments`)
    .then(getResponseData)

  return response
}

export async function postApplicationDocumentAttachments({
  applicationId,
  attachments,
}: {
  applicationId: string
  attachments: DocumentAttachment[]
}) {
  const response = await axiosInstance
    .post<APIResponse<ApplicationDocumentAttachment[]>>(
      `/autoFlow/applications/${applicationId}/documents/attachments`,
      {
        attachments: attachments.map(
          ({ productIssuer = 'OCHO', path: _path, ...attachment }) => ({
            productIssuer,
            ...attachment,
          }),
        ),
      },
      { headers: getIdemPotencyKeyHeader() },
    )
    .then(getResponseData)

  return response
}

export type GetApplicationDocumentAttachmentsFnData = Awaited<
  ReturnType<typeof getApplicationDocumentAttachments>
>

export function useGetApplicationDocumentAttachments(
  applicationId: string,
  options: UseQueryOptions<
    GetApplicationDocumentAttachmentsFnData,
    AxiosError<unknown>
  >,
) {
  return useQuery({
    queryFn: () => getApplicationDocumentAttachments({ applicationId }),
    queryKey: applicationKeys.documentsAttachments(applicationId),
    ...options,
  })
}

export function useUpdateApplicationDocumentAttachments(
  applicationId: string,
  options: UseMutationOptions<
    [
      Awaited<ReturnType<typeof postApplicationDocumentAttachments>>,
      Awaited<ReturnType<typeof updateApplication>> | null,
    ],
    AxiosError,
    {
      attachments: DocumentAttachment[]
      count: number
    }
  >,
) {
  return useMutation({
    async mutationFn({ attachments, count }) {
      const addAttachmentsResponse = await postApplicationDocumentAttachments({
        applicationId,
        attachments,
      })

      const documentOperations: UpdateApplicationField[] =
        addAttachmentsResponse
          ?.map(({ id }, index) => {
            const { path } = attachments[index]

            const pathIndex = count + index

            if (!path) return null

            return [
              { path, type: InclusionStatus.Add as const },
              { path: `${path}[${pathIndex}].documentId`, value: id },
            ]
          })
          .filter(Boolean)
          .flat() as any

      if (!documentOperations?.length) {
        return [addAttachmentsResponse, null] as any
      }

      Sentry.addBreadcrumb({
        data: { documentOperations },
        message: 'Updating application with documentOperations',
      })

      const application = await updateApplication({
        data: documentOperations,
        status: ApplicationStatus.ProofDocumentation,
      })

      return [addAttachmentsResponse, application]
    },
    mutationKey: ['send-application-document-attachments', applicationId],
    ...options,
  })
}

export function useAddTaskAttachmentAndSubmit(
  /**
   * The task id to add the attachments to
   */
  taskId: string,
  options?: UseMutationOptions<unknown, AxiosError, DocumentAttachment[]>,
) {
  const customerId = useCustomerId()

  return useMutation({
    async mutationFn(attachments) {
      await addTaskAttachment({
        attachments: attachments.map(({ comments = '', ...attachment }) => ({
          ...attachment,
          comments,
          ownerId: customerId,
          taskId,
          type: 'OTHER',
        })),
        ownerId: customerId,
        taskId,
      })

      return submitTask({ customerId, taskId })
    },
    mutationKey: ['add-attachments-and-submit'],
    ...options,
  })
}
