import { Platform } from 'react-native'
import Svg, { Path, type SvgProps } from 'react-native-svg'

const SvgComponent = (props: SvgProps) => (
  <Svg
    style={{
      height: 25,
      width: Platform.OS !== 'android' ? 'auto' : undefined,
    }}
    viewBox="0 0 25 20"
    {...props}
  >
    <Path
      d="M16.767,0c2.182,0.004 4.274,0.873 5.817,2.416c1.543,1.543 2.412,3.635 2.416,5.817c-0.015,2.176 -0.888,4.258 -2.43,5.793c-1.542,1.536 -3.627,2.401 -5.803,2.407l-8.51,0c-0.495,0 -0.988,-0.049 -1.474,-0.146l-4.739,3.086c-0.154,0.105 -0.33,0.175 -0.514,0.205c-0.185,0.029 -0.373,0.018 -0.553,-0.034c-0.17,-0.042 -0.33,-0.119 -0.47,-0.224c-0.139,-0.106 -0.256,-0.239 -0.343,-0.391c-0.087,-0.153 -0.142,-0.321 -0.162,-0.495c-0.019,-0.174 -0.004,-0.351 0.047,-0.519l1.295,-5.179c-0.878,-1.338 -1.345,-2.903 -1.344,-4.503c0.004,-2.184 0.874,-4.276 2.419,-5.82c1.545,-1.543 3.638,-2.411 5.822,-2.413l8.526,0Zm-4.323,14.267c0.161,-0 0.32,-0.032 0.469,-0.095c0.148,-0.063 0.282,-0.154 0.395,-0.27c0.112,-0.115 0.2,-0.252 0.259,-0.402c0.058,-0.15 0.086,-0.31 0.082,-0.471c-0,-0.32 -0.127,-0.626 -0.353,-0.852c-0.226,-0.226 -0.533,-0.353 -0.852,-0.353c-0.158,-0 -0.314,0.031 -0.46,0.092c-0.146,0.06 -0.278,0.149 -0.39,0.261c-0.111,0.112 -0.199,0.245 -0.259,0.391c-0.059,0.146 -0.089,0.303 -0.088,0.461c-0.005,0.16 0.023,0.32 0.081,0.469c0.058,0.15 0.146,0.286 0.257,0.401c0.112,0.116 0.245,0.207 0.392,0.271c0.148,0.063 0.306,0.096 0.467,0.097Zm0.7,-6.881c-0.486,0.236 -0.895,0.606 -1.179,1.065c-0.284,0.46 -0.432,0.992 -0.425,1.532l-0,0.774l1.873,-0l-0,-0.546c0.009,-0.322 0.109,-0.634 0.289,-0.901c0.181,-0.267 0.433,-0.477 0.729,-0.605l0.814,-0.505c0.535,-0.247 0.99,-0.641 1.311,-1.135c0.321,-0.494 0.496,-1.069 0.505,-1.658c-0,-1.906 -1.401,-3.184 -3.844,-3.184c-2.076,-0 -3.737,0.961 -4.242,2.891c-0.031,0.13 -0.034,0.265 -0.009,0.396c0.026,0.131 0.079,0.256 0.156,0.365c0.076,0.109 0.176,0.201 0.291,0.269c0.115,0.068 0.243,0.111 0.376,0.126l0.155,-0c0.233,0.023 0.468,-0.038 0.66,-0.173c0.193,-0.134 0.331,-0.333 0.39,-0.56c0.126,-0.456 0.41,-0.854 0.8,-1.122c0.39,-0.268 0.862,-0.39 1.334,-0.344c1.14,0 1.816,0.57 1.816,1.368c-0,0.798 -0.635,1.214 -1.197,1.564l-0.603,0.383Z"
      fill="#FFFFFF"
    />
  </Svg>
)

export default SvgComponent
