import { Platform } from 'react-native'
import { use } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import LocizeBackend from 'i18next-locize-backend'
import { locizePlugin } from 'locize'
import LocizeLastUsed from 'locize-lastused'
import { initReactI18next } from 'react-i18next'

import { SupportedLanguage } from '@/utils/language'

import enUS from '@/localization/en-US/translations.json'
import esUS from '@/localization/es-US/translations.json'

const isDevelopment =
  (typeof process !== 'undefined' && process.env.NODE_ENV === 'development') ||
  (typeof __DEV__ !== 'undefined' && __DEV__)

const lastUsedKey = process.env.EXPO_PUBLIC_LOCIZE_LAST_USED

const isUsingBackend =
  typeof process === 'object' && process.env.IS_STORYBOOK === 'true'

let backend
let resources
let locizeLastUsed

const locizeOptions = {
  apiKey: undefined as string | undefined,
  projectId: '177c84e6-5d38-417f-bf25-3d2dacea0a09',
  referenceLng: SupportedLanguage.EnUs,
  version: 'latest',
}

const isWeb = Platform.OS === 'web'

// eslint-disable-next-line react-hooks/rules-of-hooks
let initI18Next = use(initReactI18next)
if (lastUsedKey) {
  // To save last used language in locize stats
  initI18Next = initI18Next.use(LocizeLastUsed)

  locizeOptions.apiKey = lastUsedKey
  locizeLastUsed = locizeOptions
}
if (isWeb) {
  // Only attempt to detect language on web
  initI18Next = initI18Next.use(LanguageDetector)
}
if (isWeb && isUsingBackend) {
  // To connect to locize directly
  initI18Next = initI18Next.use(LocizeBackend)
  backend = locizeOptions
  // For inContext editor
  initI18Next = initI18Next.use(locizePlugin)
} else {
  // Use file system in production
  resources = { 'en-US': { app: enUS }, 'es-US': { app: esUS } }
}

const i18n = initI18Next.init({
  backend,
  compatibilityJSON: 'v4',
  debug: isDevelopment,
  fallbackLng: isUsingBackend ? undefined : SupportedLanguage.EnUs,

  // React already safes from xss
  interpolation: { escapeValue: false },

  locizeLastUsed,
  ns: 'app',
  react: isUsingBackend
    ? { bindI18n: 'languageChanged editorSaved' }
    : undefined,
  resources,
})

export { resources }
export default i18n
