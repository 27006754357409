export enum Events {
  SubmitApplicationStartLogIn = 'submit__application_start__log_in',
  SubmitPostalCode = 'submit__postal_code',
  CreateApplication = 'create__application',
  CreateAccount = 'create__account',

  // Get events
  PreApprove = 'get__pre_approve',
  GetRateCall1 = 'get__rate_call1',
  GetRateCall15 = 'get__rate_call1_5',
  GetRateCallFinal = 'get__rate_call_final',
  GetPolicy = 'get__policy',
  GetRenewalPolicy = 'get__renewal_policy',
  GetNotServiceable = 'get__not_serviceable',
  GetQuoteDetails = 'get__quote_details',
  GetPaymentConfirmation = 'get__payment_confirmation',

  // Submission events
  SubmitApplicantAccount = 'submit__applicant_account',
  SubmitApplicantAddress = 'submit__applicant_address',
  SubmitApplicantBirthdateConfirmation = 'submit__applicant_birthdate_confirmation',
  SubmitApplicantBirthdate = 'submit__applicant_birthdate',
  SubmitApplicantCurrentlyInsured = 'submit__applicant_currently_insured',
  SubmitApplicantGender = 'submit__applicant_gender',
  SubmitApplicantGivenName = 'submit__applicant_given_name',
  SubmitApplicantLanguage = 'submit__applicant_language',
  SubmitApplicantMaritalStatus = 'submit__applicant_marital_status',
  SubmitApplicantPhoneNumber = 'submit__applicant_phone_number',
  SubmitApplicantSsn = 'submit__applicant_ssn',
  SubmitApproval = 'submit__approval',
  SubmitLoanPremiumFinanceSignaturePreview = 'submit__loan_premium_finance_signature_preview',
  SubmitDriverDiscovery = 'submit__driver_discovery',
  SubmitDriverDrivingRecord = 'submit__driver_driving_record',
  SubmitDriverLicenseCountry = 'submit__driver_license_country',
  SubmitExpired = 'submit__expired',
  SubmitViolation = 'submit__violation',
  SubmitAccident = 'submit__accident',
  SubmitCompAndCollision = 'submit__comp_and_collision',
  SubmitIssuerQuestions = 'submit__issuer_questions',
  SubmitNextPaymentDate = 'submit__next_payment_date',
  SubmitPaymentAmount = 'submit__payment_amount',
  SubmitPaymentConfiguration = 'submit__payment_configuration',
  SubmitPaymentSchedule = 'submit__payment_schedule',
  SubmitPolicyBound = 'submit__policy_bound',
  SubmitProofOfDocumentation = 'submit__proof_of_documentation',

  SubmitRateCall1 = 'submit__rate_call1',
  SubmitRateCall15 = 'submit__rate_call1_5',
  SubmitRateCallFinal = 'submit__rate_call_final',

  SubmitDocumentsSignature = 'submit__documents_signature',
  SubmitVehicleDiscovery = 'submit__vehicle_discovery',
  SubmitVehicleMilesPerDay = 'submit__vehicle_miles_per_day',
  SubmitVehicleOwnership = 'submit__vehicle_ownership',
  SubmitVehicleYear = 'submit__vehicle_year',
  SubmitLoanPremiumFinanceSignature = 'submit__loan_premium_finance_signature',
  SubmitRequestSignUp = 'submit__request_sign_up',
  SubmitSelectPayment = 'submit__select_payment',

  // Other
  RedirectLandingPage = 'redirect__landing_page',
  SubmitPaymentExtension = 'submit__payment_extension',
  UIClickHamburgerMenuOpen = 'ui__click_hamburger_menu__open',
  UIClickCompAndCollisionDeductibleChange = 'ui__click_comp_and_collision__deductible_change',

  // Click Lead Experience
  UIClickLeadExperienceContinue = 'ui__click_lead_experience__continue',
  UIClickLeadExperienceGetStarted = 'ui__click_lead_experience__get_started',
  UIClickLeadExperienceEditInfo = 'ui__click_lead_experience__edit_info',

  // Drivers Vehicles Reset
  SubmitDriversVehiclesResetGetQuotes = 'submit__drivers_vehicles_reset__get_quotes',
  UIDriversVehiclesResetEditDrivers = 'ui__drivers_vehicles_reset__edit_drivers',
  UIDriversVehiclesResetEditVehicles = 'ui__drivers_vehicles_reset__edit_vehicles',
  UIDriversVehiclesResetViewDetails = 'ui__drivers_vehicles_reset__view_details',

  // Referral
  UIClickReferralBannerHome = 'ui__click_referral_banner__home',
  UIClickReferralBannerPayment = 'ui__click_referral_banner__payment',
  UIClickReferralBannerPaymentConfirmation = 'ui__click_referral_banner__payment_confirmation',
  UIClickReferralApplicationPolicy = 'ui__click_referral__application_policy',
  UIClickReferralSettings = 'ui__click_referral__settings',
  UIClickReferralShareSms = 'ui__click_referral__share__sms',
  UIClickReferralShareFacebookMessenger = 'ui__click_referral__share__facebook_messenger',
  UIClickReferralShareWhatsapp = 'ui__click_referral__share__whatsapp',
  UIClickReferralShareMore = 'ui__click_referral__share__more',
}
