import { useEffect, useMemo } from 'react'
import { useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import type { NavigationProps } from '@/screens/NewApplication/utils/types'

import { getBool } from '@/utils'
import type { UpdateApplicationField } from '@/utils/api/application'
import type { Quote, QuotesData } from '@/utils/api/quotes'
import {
  type GetQuotesOptions,
  NoQuotesData,
  useQuotes as useGetQuotes,
  useTriggerQuotes,
} from '@/utils/api/quotes'
import {
  ApplicationStatus,
  FAST_REFETCH_INTERVAL_MS,
  PremiumFinanceContractType,
  RateCall,
  SLOW_REFETCH_INTERVAL_MS,
} from '@/utils/constants'
import useDelighted from '@/utils/hooks/useDelighted'
import Sentry from '@/utils/sentry'

import type { Application } from '@/.d'

const TERMINAL_QUOTE_STATES = ['SUCCESS', 'ERROR', 'FAILED']

enum ArraySortOrder {
  AFirst = -1,
  BFirst = 1,
  Equal = 0,
}

function compareContractType(quoteA: Quote, quoteB: Quote) {
  if (quoteA.normalizedQuote?.isMockResponse) return ArraySortOrder.BFirst
  if (quoteB.normalizedQuote?.isMockResponse) return ArraySortOrder.AFirst

  if (
    quoteA.normalizedQuote?.premiumFinanceContractType ===
    PremiumFinanceContractType.NonFinanced
  ) {
    return ArraySortOrder.BFirst
  }
  if (
    quoteB.normalizedQuote?.premiumFinanceContractType ===
    PremiumFinanceContractType.NonFinanced
  ) {
    return ArraySortOrder.AFirst
  }

  return ArraySortOrder.Equal
}

export enum RateCallRequired {
  RateCall1 = 'quote.rateCall1Id',
  RateCall15 = 'quote.rateCall15Id',
  RateCallFinal = 'quote.rateCallFinalId',
}

export function useSurvey({
  application,
  isDisabled,
  rateCall,
  hasSomeQuotesPending,
}: {
  isDisabled: boolean
  application?: Application
  hasSomeQuotesPending: boolean
  rateCall: RateCallRequired
}) {
  const { i18n } = useTranslation()

  const { showSurvey } = useDelighted()

  useEffect(() => {
    const shouldShowSurvey =
      !isDisabled &&
      rateCall === 'quote.rateCall1Id' &&
      !hasSomeQuotesPending &&
      // Only show for spanish speaker users
      i18n.language === 'en-US'

    if (!shouldShowSurvey) return undefined

    const timeout = setTimeout(() => {
      const applicantBirthdate = application?.data?.applicant?.dateOfBirth
      const applicantBirthYear = Number(
        applicantBirthdate?.split?.('/')?.[2] ?? new Date().getFullYear(),
      )

      const applicantAge = new Date().getFullYear() - applicantBirthYear

      showSurvey({
        email: application?.data?.applicant?.emailAddress,
        name: application?.data?.applicant?.givenName,
        properties: {
          age: applicantAge,
          applicationId: application?.id,
          locale: i18n.language.replace(/-.*/, ''),
          vehicleOwnership: application?.data?.vehicles?.[0]?.ownership,
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 20 * 1000)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, hasSomeQuotesPending])
}

export const RATE_CALL_1_VALUES = [
  {
    path: 'quote.rateCall1Id',
    value: '00000000-0000-1000-a000-000000000000',
  },
  { path: 'quote.rateCall1Issuer', value: 'OCHO' },
  {
    path: 'quote.rateCall1IssuerContractType',
    value: 'STANDARD',
  },
] as UpdateApplicationField[]

/** Time in seconds */
export const QUOTES_COOLDOWN = 15
/** Time in seconds */
export const FULLSCREEN_QUOTES_COOLDOWN = 45

const MAX_ERROR_RETRIES = 2

export function useQuotes({
  enabled,
  coverageType,
  withCompAndCollision,
  upfrontPaymentAmount,
  marketPlace,
  nonSelectedAlternative,
  rateCall: requiredRateCall,
  limit,
}: { enabled: boolean } & Omit<GetQuotesOptions, 'applicationId'>) {
  const TRIGGER_WAIT_MS = 4000

  const {
    error: triggerError,
    mutate: triggerQuotes,
    isLoading: triggerIsLoading,
  } = useTriggerQuotes(
    { coverageType, withCompAndCollision },
    {
      onSuccess(data: any) {
        if (!data?.triggered) return
        Sentry.addBreadcrumb({ message: 'Trigger Quotes [success]' })
        refetch()
      },
      retryDelay: TRIGGER_WAIT_MS,
    },
  )

  const getQuotesEnabled = enabled && !triggerIsLoading && !triggerError

  const {
    refetch,
    isRefetching,
    data,
    isInitialLoading,
    isLoading,
    error,
    isSuccess,
  } = useGetQuotes(
    {
      coverageType,
      limit,
      marketPlace,
      nonSelectedAlternative,
      rateCall: requiredRateCall,
      upfrontPaymentAmount,
      withCompAndCollision,
    },
    {
      enabled: getQuotesEnabled,
      refetchInterval(data, query) {
        let interval: number | false

        if (query.state.fetchFailureCount > MAX_ERROR_RETRIES) {
          interval = false
        } else {
          const hasQuotes = Boolean(data?.quotes?.length)
          // We will refetch more often if there are pending quotes...
          const hasAllQuotesLoaded =
            data?.quotes?.length &&
            data?.quotes?.every((quote) =>
              TERMINAL_QUOTE_STATES.includes(quote?.status),
            )

          if (!hasQuotes) {
            interval = 1 // Immediate
          } else if (hasAllQuotesLoaded) {
            interval = SLOW_REFETCH_INTERVAL_MS
          } else {
            interval = FAST_REFETCH_INTERVAL_MS
          }
        }

        return interval
      },
      refetchIntervalInBackground: true,
      refetchOnMount: true,
      refetchOnReconnect: 'always',
      retry: (failureCount, error) => {
        if (error?.message === NoQuotesData) return false
        return failureCount <= MAX_ERROR_RETRIES
      },
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      retryDelay: (failureCount) => failureCount * 1000,
    },
  )
  useEffect(
    function onError() {
      Sentry.addBreadcrumb({ message: 'Get Quotes [error]' })

      if (nonSelectedAlternative) return

      // If there are no quote data, that means we need to trigger them
      if (error?.message === NoQuotesData) {
        triggerQuotes({
          rateCall: requiredRateCall,
          // Add a delay only for the first trigger, since the user linking
          // and application update takes time
          withDelay: requiredRateCall === 'RATE_CALL1',
        })
      }
    },
    [error?.message, nonSelectedAlternative, requiredRateCall, triggerQuotes],
  )
  useEffect(
    function onSuccess() {
      if (!isSuccess) return
      if (nonSelectedAlternative) return
      if (data?.type !== requiredRateCall) {
        triggerQuotes({ rateCall: requiredRateCall })
      }
    },
    [
      data?.type,
      isSuccess,
      nonSelectedAlternative,
      requiredRateCall,
      triggerQuotes,
    ],
  )

  return useMemo(
    () => ({
      data,
      isInitialLoading,
      isLoading,
      isRefetching,
      refetch,
      triggerError,
      triggerIsLoading,
    }),
    [
      data,
      isLoading,
      isInitialLoading,
      isRefetching,
      refetch,
      triggerError,
      triggerIsLoading,
    ],
  )
}

export function getRequiredRateCall(
  currentRateCall: RateCallRequired,
): RateCall {
  switch (currentRateCall) {
    case 'quote.rateCall1Id':
      return RateCall.Rc1
    case 'quote.rateCall15Id':
      return RateCall.Rc2
    case 'quote.rateCallFinalId':
      return RateCall.Rc3
    default:
      throw new Error('Invalid rate call')
  }
}

export function getRateCallFromApplicationStatus(status?: ApplicationStatus) {
  switch (status) {
    case ApplicationStatus.QuotesRateCall1:
    case ApplicationStatus.QuotesRateCall1Pending:
    case ApplicationStatus.QuotesRateCall1Anon:
    case ApplicationStatus.QuotesRateCall1PendingAnon:
      return RateCall.Rc1
    case ApplicationStatus.QuotesRateCall15:
    case ApplicationStatus.QuotesRateCall15Pending:
      return RateCall.Rc2
    case ApplicationStatus.QuotesFinal:
    case ApplicationStatus.QuotesFinalPending:
    case ApplicationStatus.QuotesFinalAccepted:
    case ApplicationStatus.QuotesFinalBounced:
    case ApplicationStatus.QuotesFinalAgentLocked:
      return RateCall.Rc3
    default:
      return RateCall.Rc1
  }
}

export type GetQuotesParams = {
  data?: QuotesData
  coverageType?: string
  requiredRateCall: string
  filterById?: string | null
  withCompAndCollision?: boolean
}

/**
 * Will return quotes filtered, ordered and with some flags
 */
export function useMetaQuotes(
  options: GetQuotesParams & { sortFirst?: string },
) {
  const {
    data,
    sortFirst,
    coverageType,
    filterById,
    requiredRateCall,
    withCompAndCollision,
  } = options

  return useMemo(() => {
    let filteredQuotes =
      data?.type && data.type !== requiredRateCall ? [] : (data?.quotes ?? [])

    if (typeof withCompAndCollision === 'boolean') {
      filteredQuotes =
        data?.withCompAndCollision === withCompAndCollision
          ? filteredQuotes
          : []
    }
    if (coverageType) {
      filteredQuotes = data?.coverageType === coverageType ? filteredQuotes : []
    }

    const hasSomeQuotesPending = filteredQuotes.some(
      (quote) => quote?.status === 'PENDING',
    )
    const hasSomeQuotesReady = filteredQuotes.some(
      (quote) => quote?.status === 'SUCCESS',
    )

    filteredQuotes = filteredQuotes.filter(
      (quote) =>
        (quote?.status === 'SUCCESS' &&
          quote?.normalizedQuote?.success === true) ||
        quote?.status === 'PENDING',
    )

    if (filterById) {
      // If the user has selected a quote, we don't want to show the list of quotes.
      // we will show the one they selected on a disabled state with a loader below it.
      filteredQuotes = filteredQuotes.filter((quote) => quote.id === filterById)
    }

    const sortedQuotes = filteredQuotes?.sort((quoteA, quoteB) => {
      if (quoteA.id === sortFirst) {
        return ArraySortOrder.AFirst
      }
      if (quoteB.id === sortFirst) {
        return ArraySortOrder.BFirst
      }

      if (
        quoteA.status === 'SUCCESS' &&
        quoteA.normalizedQuote?.success === true &&
        quoteB.status === 'SUCCESS' &&
        quoteB.normalizedQuote?.success === true
      ) {
        return compareContractType(quoteA, quoteB)
      }

      if (quoteA.status !== quoteB.status) {
        if (quoteA.status === 'PENDING') return ArraySortOrder.BFirst
        if (quoteB.status === 'PENDING') return ArraySortOrder.AFirst
      }

      return ArraySortOrder.Equal
    })

    const hasOchoPayQuotes = Boolean(
      filteredQuotes.filter(
        (quote) =>
          quote.normalizedQuote?.premiumFinanceContractType &&
          quote.normalizedQuote?.premiumFinanceContractType !==
            PremiumFinanceContractType.NonFinanced &&
          quote.status === 'SUCCESS',
      )?.length,
    )

    return {
      hasOchoPayQuotes,
      hasSomeQuotesPending,
      hasSomeQuotesReady,
      quotes: sortedQuotes,
    }
  }, [
    coverageType,
    data?.coverageType,
    data?.quotes,
    data?.type,
    data?.withCompAndCollision,
    filterById,
    requiredRateCall,
    sortFirst,
    withCompAndCollision,
  ])
}

export const OCHO_RATE_CALL = [
  {
    path: 'quote.rateCall1Id',
    value: '00000000-0000-1000-a000-000000000000',
  },
  { path: 'quote.rateCall1Issuer', value: 'OCHO' },
  {
    path: 'quote.rateCall1IssuerContractType',
    value: 'STANDARD',
  },
]

export function useWithCompAndCollision() {
  const { params } = useRoute<NavigationProps['route']>()

  return getBool(params?.withCompAndCollision)
}

export function useCoverageType() {
  const { params } = useRoute<NavigationProps['route']>()

  return params?.coverageType as 'BASIC_PLUS' | 'BASIC' | undefined
}

export function getIncluded(data?: any[]) {
  return data?.filter(({ inclusionStatus }: any) => inclusionStatus === 'ADD')
}
